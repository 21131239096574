import { axios } from "./axios";

export class VolunteersApi {
    getAll() {
        return axios.get<Volunteer[]>("/volunteers");
    }

    save({ volunteer }: { volunteer: Volunteer }) {
        return axios.post("/volunteers", volunteer);
    }

    edit({ volunteer }: { volunteer: Volunteer }) {
        return axios.put("/volunteers", volunteer);
    }
}

export interface Volunteer {
    id: number;
    firstName: string;
    lastName: string;
    gdpr: boolean;
    volunteer: boolean;
    registrationDate: Date;
    safeGuardingDate: Date;
    gardaVettingDate: Date;
}
