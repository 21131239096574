



































































































































import { Component, Vue } from "vue-property-decorator";
import { VolunteersApi, Volunteer } from "@/store/volunteers-api";
import { required, maxLength } from "@/common/validators/rules";

@Component({})
export default class VolunteerForm extends Vue {
    volunteersStore = new VolunteersApi();
    validators = { required, maxLength };
    volunteer: Volunteer = {} as Volunteer;
    thanks = false;

    submit() {
        if (!(this.$refs.form as any).validate()) {
            return;
        }

        this.volunteersStore.save({ volunteer: this.volunteer }).then(() => {
            (this.$refs.form as any).reset();
            this.thanks = true;
        });
    }
}
